<template>
  <h3>{{ $t("select_org") }}</h3>
  <div class="loginContent" v-if="organizationList.length">
    <ul class="org-list org-list-page">
      <li
        v-for="organization in organizationList"
        :key="organization.id"
        v-on:click.prevent="
          saveOrganization(
            organization.organization.name,
            organization.organization.id,
            organization.organization.logo
          )
        "
      >
        <div class="pointer">
          <img
            :src="
              organization.organization.logo
                ? imgURL + organization.organization.logo
                : '/assets/images/logo/icon.svg'
            "
            title="Organization"
          />
          {{ organization.organization.name }}
        </div>
      </li>
    </ul>
  </div>
  <div class="no-org" v-else>
    <p class="text-danger">{{ $t("no_org") }}</p>
    <span class="logout" @click="handleLogout">logout</span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_ORGANIZATIONS_ACTION,
  GET_ORGANIZATIONS_GETTER,
  GET_USER_PROFILE_ACTION,
  GET_USER_PROFILE_GETTER,
  LOGOUT_USER
} from "@/store/storeconstants";

export default {
  title() {
    return `Remote Support | ${this.title}`;
  },
  data() {
    return {
      title: "Organizations",
      organizationList: [],
      imgURL: process.env.VUE_APP_DO_SPACES,
      client_roles: [9, 10],
    };
  },
  computed: {
    ...mapGetters("organization", {
      getOrganizationList: GET_ORGANIZATIONS_GETTER,
    }),
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
  },
  methods: {
    ...mapActions("organization", {
      getOrganizations: GET_ORGANIZATIONS_ACTION,
    }),
    ...mapActions("account", {
      getUserData: GET_USER_PROFILE_ACTION,
    }),
    ...mapActions("auth", {
        logout: LOGOUT_USER,
    }),

    async fetchOrganizations() {
      try {
        await this.getOrganizations();
        this.organizationList.push(...this.getOrganizationList);
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async saveOrganization(name, id, logo) {
      let organizationData = {
        id: id,
        name: name,
        logo: logo,
      };
      localStorage.setItem("organization", JSON.stringify(organizationData));
      await this.getUserData().then(() => {
        const role = this.getUserProfile.role?.id || null;
        if (!role) this.$router.push("/clients");
        else this.$router.push("/data-structure");
      });
    },
    handleLogout() {
        this.logout();
        this.emitter.emit("logout");
        this.$router.replace("/");
        this.$emit('close')
    },
  },
  created() {
    this.fetchOrganizations();
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.no-org {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  .logout {
    cursor: pointer;
    user-select: none;
    background: $red;
    padding: 0.5rem 1rem;
    color: white;
    text-transform: capitalize;
    transition: 0.2s;

    &:hover {
        background: $red-hover;
    }
  }
}


</style>